import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useTranslation } from "gatsby-plugin-react-i18next"
import iski1 from "../../assets/img/iski-1.png"
import iski2 from "../../assets/img/iski-2.png"

export default function IskiProjesi() {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title="İski Projesi" />
      <div className="projeler-container page">
        <div className="ic-proje">
          <img src={iski2} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>:  {t("iski")}
            <br />
            <span>{t("project-sector")}</span>: {t("kamu-kurumu")}
            <br />
            <span>{t("project-subject")}</span>: {t("sanat-projesi")}
            <br />
            <span>{t("project-artwork")}</span>: {t("damla-koleksiyon")}
          </div>
          <img src={iski1} alt="" className="proje-icGorsel" />
        </div>
      </div>
    </Layout>
  )
}
